import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import { omit } from "lodash";
import React from "react";

export const ChevronPixelatedIcon = (props: React.ComponentProps<typeof StyledSvg> & { direction: "up" | "down" | "left" | "right" }) => {
  const [_, $theme] = useStyletron();
  const rotation = getRotation(props.direction);

  return (
    <StyledSvg viewBox="0 0 15 15" {...omit(props, "direction")}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.83333 1.66602H4.5V3.99935H6.83333V1.66602ZM6.83594 4H9.16927V6.33203H11.4974V8.66536H9.16406V6.33333H6.83594V4ZM6.83594 8.66602H9.16927V10.9993H6.83594V8.66602ZM6.83333 11H4.5V13.3333H6.83333V11Z"
        fill={$theme.colors.primary400}
        style={{
          transform: `rotate3d(0, 0, 1, ${rotation}deg)`,
        }}
      />
    </StyledSvg>
  );
};

const getRotation = (direction: "up" | "down" | "left" | "right") => {
  if (direction == "down") {
    return 90;
  } else if (direction == "left") {
    return 180;
  } else if (direction == "up") {
    return -90;
  }
  return 0;
};
